import { loadManifest } from '@angular-architects/module-federation';

// Nada más arrancar, miro si el nombre de la aplicación está guardado en el Storage, en ese caso actualizo el Title
var appName = localStorage.getItem('APPLICATION_NAME_STORAGE_KEY');
document.title = JSON.parse(appName) ?? 'Endalia';

// Cargo el manifest
loadManifest("assets/mf.manifest.json")
  .catch(err => console.error(err))
  .then(_ => import('./bootstrap'))
  .catch(err => console.error(err));
